// ==UserScript==
// @name Robots' Revenge Solver Link
// @description Shows a link to kevincox's Ricochet Robot solver for Robot's revenge puzzles.
// @include https://revenge.jcpmcdonald.com/*
// @namespace https://userscripts.kevincox.ca
// @version 2
// @grant none
// @run-at document-idle
// ==/UserScript==

let a = document.createElement("a");
a.style.position = "absolute";
a.style.top = "0";
a.style.right = "0";
a.style.padding = "0.2em 0.4em";
a.style.color = "white";
a.href = "#";
a.textContent = "Solver";
document.body.append(a);

a.onmousedown = (e) => {
	e.stopPropagation();

	const WIDTH = 16;
	const HEIGHT = 16;

	const ROBOT_OFFSET = 3;
	const TARGET_OFFSET = 0;

	class Board {
		verticalWalls: Record<string, true> = {};
		horizontalWalls: Record<string, true> = {};
		robots: [number, number][] = [];
		targetColor = 0;
		targetLocation: [number, number] = [0, 0];

		toCompact() {
			let out: number[] = [];

			out.push(16, 16);
			for (let x = 0; x < 16; x++) {
				let bits = 0;
				for (let y = 15; y >= 0; y--) {
					bits <<= 1;
					if (this.horizontalWalls[[x, y] as any]) {
						bits |= 1;
					}
				}
				out.push(bits & 0xFF, bits >> 8);
			}
			for (let y = 0; y < 16; y++) {
				let bits = 0;
				for (let x = 15; x >= 0; x--) {
					bits <<= 1;
					if (this.verticalWalls[[x, y] as any]) {
						bits |= 1;
					}
				}
				out.push(bits & 0xFF, bits >> 8);
			}

			out.push(0); // Mirrors

			out.push(this.robots.length);
			for (let r of this.robots) {
				out.push(r[0] << 4 | r[1]);
			}

			out.push(this.targetLocation[0] << 4 | this.targetLocation[1]);
			out.push(this.targetColor);

			return btoa(String.fromCharCode(...out))
				.replaceAll("+", "-")
				.replaceAll("/", "_")
				.replaceAll("=", "");
		}
	}

	let root =  document.querySelector<HTMLElement>(`div:has(>${Array(HEIGHT).fill("div").join("+")})`)!;
	let rows = ([...root.children] as HTMLElement[]).slice(0, 16)
		.map(row => ([...row.children] as HTMLElement[]).filter(cell => !cell.style.position));
	let [red, blue, green, yellow] = ([...root.children] as HTMLElement[]).slice(18);

	let cellWidth = parseFloat(rows[0][0].style.width);

	let board = new Board;

	function isWallBorder(width: CSSStyleDeclaration["borderTopWidth"]): boolean {
		return width === "3px";
	}

	for (let y = 0; y < HEIGHT; y++) {
		for (let x = 0; x < WIDTH; x++) {
			let cell = rows[y][x];
			if (isWallBorder(cell.style.borderTopWidth)) board.horizontalWalls[[x,y] as any] = true;
			if (isWallBorder(cell.style.borderLeftWidth)) board.verticalWalls[[x,y] as any] = true;
		}
	}

	// Make a cross in the middle block to prevent black from moving.
	// This makes solving about 4x faster.
	board.horizontalWalls["7,8"] = true;
	board.horizontalWalls["8,8"] = true;
	board.verticalWalls["8,7"] = true;
	board.verticalWalls["8,8"] = true;

	function robotPos(width: number): number {
		return Math.round((width - ROBOT_OFFSET) / cellWidth)
	}

	board.robots.push([8, 8]);
	for (let robot of [red, green, yellow, blue]) {
		let x = robotPos(parseFloat(robot.style.left));
		let y = robotPos(parseFloat(robot.style.top))
		board.robots.push([x, y]);
	}

	let target = document.querySelector<HTMLElement>("[aria-label=Objective]")!;
	function targetPos(width: number): number {
		return Math.round((width - TARGET_OFFSET) / cellWidth)
	}

	board.targetLocation = [
		targetPos(parseFloat(target.style.left)),
		targetPos(parseFloat(target.style.top)),
	];

	const TARGET_COLORS: Record<string, number> = {
		"rgb(255, 0, 0)": 1,
		"rgb(0, 170, 0)": 2,
		"rgb(255, 215, 0)": 3,
		"rgb(50, 50, 255)": 4,
	};

	let targetBackground = target.style.backgroundColor;
	board.targetColor = TARGET_COLORS[targetBackground] ?? 0;
	console.log("target color", board.targetColor, targetBackground);

	a.href = `https://ricochetrobots.kevincox.ca/#s=${board.toCompact()}`;
};
